import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout, Books, Pagination, Scroll } from "../../components"

const BooksPage = ({ data, pageContext }) => {
  const {
    allContentfulBook: { nodes: books },
  } = data

  // console.log("poems.js data", data)
  // console.log("poems.js pagecontext", pageContext)

  return (
    <Wrapper>
      <Layout>
        <Books
          title="Összes fordítás (könyvek)"
          books={books}
          page
          link="translation"
        />
        <Scroll
          // showBelow={1500}
          css="
        position: fixed; 
        right: 5rem; 
        bottom: 6rem; 
        font-size: 1.7rem;
        "
        />
        <Pagination pageContext={pageContext} />
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  ${"" /* background from Poems.js  */}
  background: var(--clr-dark-5);
  padding-top: 5rem;
  nav {
    background-color: rgba(0, 0, 0, 0.85);
  }
`

export const query = graphql`
  query BooksQuery($skip: Int, $limit: Int) {
    allContentfulBook(
      filter: { author: { authorFullName: { ne: "Pék Zoltán" } } }
      limit: $limit
      skip: $skip
      sort: { fields: [bookYear___bookYear, createdAt], order: [DESC, DESC] }
    ) {
      nodes {
        contentful_id
        bookTitle
        author {
          authorFullName
        }
        publisher {
          publisherName
        }
        bookYear {
          bookYear
        }
        bookSize
        bookImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
        }
        bookText {
          childMarkdownRemark {
            html
            excerpt(format: HTML, pruneLength: 100)
          }
        }
      }
    }
  }
`

export default BooksPage
